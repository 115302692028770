import * as React from 'react';
import { IndustriesBody } from '@containers';
import { Layout } from '@components';
import { graphql } from 'gatsby';
import { SEO } from '../components/seo';

const Industries = ({ data }) => {
  if (!data?.allMarkdownRemark?.edges?.[0]) {
    return '';
  }
  return (
    <Layout>
      <IndustriesBody pageData={data?.allMarkdownRemark?.edges?.[0]?.node?.frontmatter} />
    </Layout>
  );
};

export default Industries;
export const Head = ({ data }) => {
  return <SEO {...data?.allMarkdownRemark?.edges?.[0]?.node?.frontmatter?.seo} />;
};
export const industriesPageQuery = graphql`
  query IndustriesPageQuery {
    allMarkdownRemark(filter: { frontmatter: { containerPath: { eq: "industriesBody/IndustriesBody" } } }) {
      edges {
        node {
          frontmatter {
            containerPath
            industriesHeroContainer {
              heroBg
              heroBgIcon
              pageName
              title
            }
            industriesImageGridData {
              heading1
              heading2
              id
              src1
              src2
              text1
              text2
            }
            seo {
              title
              favicon
              meta {
                name
                content
              }
            }
          }
        }
      }
    }
  }
`;
